import React, { useState } from 'react';
import { TailSpin } from 'react-loader-spinner';
import {
    OutlinedInput,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from '@mui/material';
import { ITokens } from '../../interfaces';

const TokenStakingAdmin = () => {
    const [tokens, setTokens] = useState<ITokens[]>([]);
    const [selectedIndex, setSelectedIndex] = useState<number | undefined>();
    const [isAddingValidator, setIsAddingValidator] = useState<boolean>(false);
    const [isRemovingValidator, setIsRemovingValidator] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isProcessing, setIsProcessing] = useState<boolean>(false);

    return (

        <div className='flex flex-col items-center gap-[30px] w-full'>
            <h1 className='text-[42px]'>Manage Token Staking</h1>

            {/* Manage tokens */}
            <div className='flex flex-col items-center w-full max-w-[650px]'>
                <h1 className='w-full text-[32px] text-center'>Manage tokens</h1>

                {/* Register token */}
                <div className='flex flex-col gap-[20px] mt-[20px] w-full'>
                    <h3 className='text-[18px]'>Register token</h3>

                    <div className='flex flex-col w-full gap-[10px]'>
                        {/* Token Symbol */}
                        <div className='flex justify-center items-center gap-[10px]'>
                            <div className='flex-none w-[165px]'>Token Symbol:</div>

                            <OutlinedInput
                                id='token-symbol'
                                aria-describedby='token-symbol-text'
                                fullWidth
                                className='grow'
                                placeholder='Token Symbol'
                                sx={{
                                    '& .MuiOutlinedInput-input': {
                                        padding: '7px 10px'
                                    }
                                }}
                            />
                        </div>

                        {/* Token mint */}
                        <div className='flex justify-center items-center gap-[10px]'>
                            <div className='flex-none w-[165px]'>Token Mint:</div>

                            <OutlinedInput
                                id='token-mint'
                                aria-describedby='token-mint-text'
                                fullWidth
                                className='grow'
                                placeholder='Token Mint Address'
                                sx={{
                                    '& .MuiOutlinedInput-input': {
                                        padding: '7px 10px'
                                    }
                                }}
                            />
                        </div>


                        {/* JWLToken metadata uri */}
                        <div className='flex justify-center items-center gap-[10px]'>
                            <div className='flex-none w-[165px]'>JWLToken MetadataUri:</div>

                            <OutlinedInput
                                id='jwltoken-metadata-uri'
                                aria-describedby='jwltoken-metadata-uri-text'
                                fullWidth
                                className='grow'
                                placeholder='Metadata Uri'
                                sx={{
                                    '& .MuiOutlinedInput-input': {
                                        padding: '7px 10px'
                                    }
                                }}
                            />
                        </div>

                        {/* Register button */}
                        <div className='flex justify-end items-center'>
                            <button
                                disabled={isProcessing || isAddingValidator}
                                className='flex justify-center items-center min-w-[120px] p-[7px_21px] rounded-[5px] btn-primary2'
                            >
                                {
                                    !isAddingValidator ? (
                                        <span>Register</span>
                                    ) : (
                                        <div className='flex justify-center items-center gap-[10px]'>
                                            <span className='text-white'>Processing...</span>
                                            <TailSpin
                                                height="18"
                                                width="18"
                                                color="#ffffff"
                                                ariaLabel="tail-spin-loading"
                                                radius="1"
                                                wrapperStyle={{}}
                                                wrapperClass=""
                                                visible={true}
                                            />
                                        </div>
                                    )
                                }
                            </button>
                        </div>
                    </div>
                </div>

                {/* Remove token */}
                <div className='flex flex-col mt-[30px] w-full'>
                    <h3 className='text-[18px]'>Remove token</h3>

                    {
                        !isLoading ? (
                            tokens.length > 0 ? (
                                <TableContainer>
                                    <Table sx={{ width: '100%' }} aria-label="simple table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>TokenMint</TableCell>
                                                <TableCell>TokenSymbol</TableCell>
                                                <TableCell align="center">Action</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {tokens.map((row, index) => (
                                                <TableRow
                                                    key={index}
                                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                >
                                                    <TableCell component="th" scope="row">
                                                        {row.tokenMint.toString()}
                                                    </TableCell>
                                                    <TableCell component="th" scope="row">
                                                        {row.tokenSymbol}
                                                    </TableCell>
                                                    <TableCell align="center">
                                                        <button
                                                            disabled={isProcessing || isRemovingValidator}
                                                            className='flex justify-center items-center min-w-[80px] p-[7px_21px] rounded-[5px] btn-primary2 mx-auto'
                                                        >
                                                            {
                                                                !(isRemovingValidator && selectedIndex == index) ? (
                                                                    <span>Remove</span>
                                                                ) : (
                                                                    <div className='flex justify-center items-center gap-[10px]'>
                                                                        <span className='text-white'>Processing...</span>
                                                                        <TailSpin
                                                                            height="18"
                                                                            width="18"
                                                                            color="#ffffff"
                                                                            ariaLabel="tail-spin-loading"
                                                                            radius="1"
                                                                            wrapperStyle={{}}
                                                                            wrapperClass=""
                                                                            visible={true}
                                                                        />
                                                                    </div>
                                                                )
                                                            }
                                                        </button>
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            ) : (
                                <div className='flex justify-center items-center'>
                                    <span className='text-[#808080]'>No items</span>
                                </div>
                            )
                        ) : (
                            <div className='flex justify-center items-center gap-[10px]'>
                                <span className='text-[#808080]'>Loading...</span>
                                <TailSpin
                                    height="18"
                                    width="18"
                                    color="#808080"
                                    ariaLabel="tail-spin-loading"
                                    radius="1"
                                    wrapperStyle={{}}
                                    wrapperClass=""
                                    visible={true}
                                />
                            </div>
                        )
                    }
                </div>
            </div>
        </div>
    );
};

export default TokenStakingAdmin;
import React, { useState } from 'react';
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    InputAdornment,
    InputBase,
    LinearProgress,
} from '@mui/material';
import {
    KeyboardArrowDown as KeyboardArrowDownIcon,
    KeyboardArrowUp as KeyboardArrowUpIcon
} from '@mui/icons-material';
import solLogo from '../../assets/images/sol_logo.png';
import hadesLogo from '../../assets/images/hades.png';

const HadesStake = () => {
    const [isTokenStakeExpanded, setIsTokenStakedExpanded] = useState<boolean>(false);

    return (
        <>
            <div className='flex flex-col gap-[7px] w-full'>
                <div className='text-[14px]'>Convert HADES to JWLHADES. By staking JWLHADES, you're earning a share of the LP's boosted HADES earnings.</div>
                <div className='text-[14px]'>You may stake and unstake JWLHADES tokens, but not convert them back to HADES at JewelSwap. A dynamic % will also be sent for LP creation. You can now swap HADES-JWLHADES at Ashswap.</div>
            </div>

            <div className='flex flex-col items-center mt-[21px]'>
                <div className='text-[21px] text-[#54f5b7] leading-[1.2]'>{`Epoch 30`}</div>
                <div className='mt-[21px] font-light'>{`0d 15h 30m 41s left until next rewards distribution`}</div>
                <div className='w-full h-[14px] mt-[14px]'>
                    <LinearProgress
                        value={80}
                        variant='determinate'
                        sx={{
                            height: '14px',
                            width: '100%',
                            borderRadius: '5px',
                            '&.MuiLinearProgress-root': {
                                backgroundColor: 'white',

                                '.MuiLinearProgress-bar': {
                                    backgroundColor: '#198754',
                                    backgroundImage: 'linear-gradient(45deg,hsla(0,0%,100%,.15) 25%,transparent 0,transparent 50%,hsla(0,0%,100%,.15) 0,hsla(0,0%,100%,.15) 75%,transparent 0,transparent)',
                                    backgroundSize: '14px 14px'
                                }
                            }
                        }}
                    />
                </div>
                <div className='mt-[14px]'><span className='mr-[14px]'>Epoch Rewards:</span> 33,285.5613 JWLHADES</div>
            </div>

            <div className='flex flex-col gap-[21px] w-full mt-[14px]'>
                {/* Token staking */}
                <div className='w-full bg-[rgb(18,18,18)] bg-[linear-gradient(rgba(255,255,255,0.05),rgba(255,255,255,0.05))] rounded-[4px]'>
                    <Accordion
                        expanded={isTokenStakeExpanded}
                    >
                        <AccordionSummary
                            id='panel1a-header'
                            aria-controls='panel1a-content'
                            className='w-full !cursor-default'
                        >
                            <div className='grid grid-cols-12 w-full text-[12.6px] px-[10.5px]'>
                                <div className='col-span-12 md:col-span-3 flex items-center md:px-[10.5px]'>
                                    <div className='flex items-center'>
                                        <div className='flex justify-center items-center w-[50px] h-[50px] mx-[7px]'>
                                            <img src={hadesLogo} alt='jwlsol-logo' className='w-full rounded-full' />
                                        </div>
                                        <div className='flex flex-col justify-center'>
                                            <div>HADES</div>
                                        </div>
                                    </div>
                                </div>

                                <div className='col-span-12 md:col-span-2 flex flex-col justify-center md:px-[10.5px]'>
                                    <div>TVL</div>
                                    <div>{`100 HADES`}</div>
                                </div>

                                <div className='col-span-12 md:col-span-1 flex flex-col justify-center md:px-[10.5px]'>
                                    <div>APY</div>
                                    <div className='text-[#54f5b7]'>{`10 %`}</div>
                                </div>

                                <div className='col-span-12 md:col-span-3 flex items-center md:px-[10.5px]'>
                                    <div className='flex flex-col w-full'>
                                        <div className='flex justify-between items-center w-full'>
                                            <div>Your balance</div>
                                            <div className='text-end'>
                                                <div>{`100 HADES`}</div>
                                                <div>{`100 JWLHADES`}</div>
                                            </div>
                                        </div>

                                        <div className='flex justify-between items-center w-full'>
                                            <div>Staked balance</div>
                                            <div className='text-end'>
                                                <div>{`-`}</div>
                                            </div>
                                        </div>

                                        <div className='flex justify-between items-center w-full'>
                                            <div>Expected Rewards</div>
                                            <div className='text-end'>
                                                <div>{`-`}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className='col-span-12 md:col-span-1 md:px-[10.5px]'></div>

                                <div className='col-span-12 md:col-span-2 flex items-center md:px-[10.5px]'>
                                    <button
                                        className='flex justify-center items-center w-full p-[7px] border border-solid border-[rgba(84,245,183,0.6)] rounded-[5px]'
                                        onClick={() => setIsTokenStakedExpanded(!isTokenStakeExpanded)}
                                    >
                                        <span>Stake / Unstake</span>
                                        {
                                            isTokenStakeExpanded ? (
                                                <KeyboardArrowUpIcon className='text-white' />
                                            ) : (
                                                <KeyboardArrowDownIcon className='text-white' />
                                            )
                                        }
                                    </button>
                                </div>
                            </div>
                        </AccordionSummary>

                        <AccordionDetails
                            sx={{ padding: '0px 16px 16px' }}
                        >
                            <div className='grid grid-cols-12 w-full mt-[14px]'>
                                <div className='col-span-12 md:col-span-6'>
                                    <div className='grid grid-cols-12 gap-[10px] md:gap-0 w-full'>
                                        <div className='col-span-12 md:col-span-6 flex flex-col px-[10.5px]'>
                                            <div className='h-[33px]'>{`Avail HADES Balance: 100 HADES`}</div>

                                            <InputBase
                                                sx={{
                                                    flex: 1,
                                                    border: '1px',
                                                    borderStyle: 'solid',
                                                    borderRadius: '5px',
                                                    borderColor: 'gray',
                                                    padding: '2px 5px',
                                                }}
                                                endAdornment={
                                                    <InputAdornment
                                                        position="end"
                                                        sx={{ cursor: 'pointer' }}
                                                    >
                                                        Max
                                                    </InputAdornment>
                                                }
                                                type='number'
                                                placeholder='0.00'
                                                className='mt-[3.5px]'
                                            />

                                            <button
                                                className='flex justify-center items-center w-fit p-[7px_21px] mt-[7px] btn-primary2'
                                            >
                                                <span>Convert</span>
                                            </button>
                                        </div>

                                        <div className='col-span-12 md:col-span-6 flex flex-col px-[10.5px]'>
                                            <div className='h-[33px]'>{`Avail JWLHADES Balance: 100 JWLHADES`}</div>

                                            <InputBase
                                                sx={{
                                                    flex: 1,
                                                    border: '1px',
                                                    borderStyle: 'solid',
                                                    borderRadius: '5px',
                                                    borderColor: 'gray',
                                                    padding: '2px 5px',
                                                }}
                                                endAdornment={
                                                    <InputAdornment
                                                        position="end"
                                                        sx={{ cursor: 'pointer' }}
                                                    >
                                                        Max
                                                    </InputAdornment>
                                                }
                                                type='number'
                                                placeholder='0.00'
                                                className='mt-[3.5px]'
                                            />

                                            <button
                                                className='flex justify-center items-center w-fit p-[7px_21px] mt-[7px] btn-primary2'
                                            >
                                                <span>Stake</span>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-span-12 md:col-span-6'>
                                    <div className='grid grid-cols-12 gap-[10px] md:gap-0 w-full'>
                                        <div className='col-span-12 md:col-span-6 flex flex-col px-[10.5px]'>
                                            <div className='h-[33px]'>{`Staked JWLHADES. Balance: -`}</div>

                                            <InputBase
                                                sx={{
                                                    flex: 1,
                                                    border: '1px',
                                                    borderStyle: 'solid',
                                                    borderRadius: '5px',
                                                    borderColor: 'gray',
                                                    padding: '2px 5px',
                                                }}
                                                endAdornment={
                                                    <InputAdornment
                                                        position="end"
                                                        sx={{ cursor: 'pointer' }}
                                                    >
                                                        Max
                                                    </InputAdornment>
                                                }
                                                type='number'
                                                placeholder='0.00'
                                                className='mt-[3.5px]'
                                            />

                                            <button
                                                className='flex justify-center items-center w-fit p-[7px_21px] mt-[7px] btn-primary2'
                                            >
                                                <span>Unstake</span>
                                            </button>
                                        </div>

                                        <div className='col-span-12 md:col-span-6 flex flex-col px-[10.5px]'>
                                            <div className='flex flex-col justify-between items-center'>
                                                <div className='flex flex-col items-center'>
                                                    <div className='text-[21px] font-light h-[33px]'>Total Rewards</div>
                                                    <div>-</div>
                                                </div>

                                                <button
                                                    className='flex justify-center items-center w-fit p-[7px_21px] mt-[7px] btn-primary2'
                                                >
                                                    <span>Claim</span>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </AccordionDetails>
                    </Accordion>
                </div>
            </div>
        </>
    );
};

export default HadesStake;